import { createSlice } from '@reduxjs/toolkit'

import { usersStatistics, usersSearch, usersData } from '../../adapters/UserStatisticsAdapter';

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        userStatistics: null,
        userData: null,
        userDataIsLoading: false,
        prefix: '',
        searchList: null,
        isLoading:false
    },
    reducers: {
        UserStaticsSuccess: (state, action) => {
            state.userStatistics = action.payload;
        },
        UserDataSuccess: (state, action) => {
            state.userData = action.payload;
        },
        updateUserDataIsLoading: (state, action) => {
            state.userDataIsLoading = action.payload
        },
        updatePrefixSuccess: (state, action) => {
            state.prefix = action.payload
        },
        updateSearchListSuccess: (state, action) => {
            state.searchList = action.payload
        },
        updateIsLoading:(state, action) => {
            state.isLoading = action.payload
        }
    },
});
export default slice.reducer

// Actions
const { UserStaticsSuccess, UserDataSuccess, updateUserDataIsLoading, updatePrefixSuccess, updateSearchListSuccess,updateIsLoading } = slice.actions

export const fetchUserStatistics = () => async (dispatch: any) => {
    try {
        const res = await usersStatistics()
        dispatch(UserStaticsSuccess(res.data));
    } catch (e) {
        return console.error(e);
    }
}

export const fetchAllUserData = () => async (dispatch: any) => {
    try {
        dispatch(updateUserDataIsLoading(true));
        const res = await usersData()
        dispatch(UserDataSuccess(res.data));
        dispatch(updateUserDataIsLoading(false));
    } catch (e) {
        dispatch(updateUserDataIsLoading(false));
        return console.error(e);
    }
}

export const updateprefix = (value: string) => async (dispatch: any) => {
    try {
        dispatch(updatePrefixSuccess(value));
    } catch (e) {
        return console.error(e);
    }
}

export const fetchUserSearch = (prefix: string) => async (dispatch: any) => {
    try {
        dispatch(updateIsLoading(true))
        const res = await usersSearch(prefix)
        dispatch(updateSearchListSuccess(res.data));
    } catch (e) {
        return console.error(e);
    }
    finally{
        dispatch(updateIsLoading(false))
    }
}