import React, { ReactNode, useEffect } from "react";
import alasql from "alasql";
import dayjs from 'dayjs';
import _ from "lodash";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserData } from "../../redux/store/user";
import { Spinner } from "@cd/sdds-common-components-react";

type ExcelExportProps = {
  children?: ReactNode
}


const UsersExcelExport = ({ children }: ExcelExportProps) => {
  const dispatch = useDispatch()
  const { user }: any = useSelector(state => state)

  const getAllData = () => {
    dispatch(fetchAllUserData())
  }
  useEffect(() => {
    if (!user?.userData?.length) return;
    exportUsersToExcel(cloneDeep(user.userData))
  }, [user.userData]);

  const getColumnsConfig = () => {
    return {
      headers: true,
      column: {
        style: 'font-size:16px'
      },
      columns: [
        { columnid: 'scaniaId', title: 'Scania ID', sortable: true },
        { columnid: 'firstName', title: 'First Name', sortable: true },
        { columnid: 'lastName', title: 'Last Name', sortable: true },
        { columnid: 'email', title: 'Email ID', sortable: true },
        { columnid: 'role', title: 'User Role', sortable: true },
        { columnid: 'lastLogin', title: 'Last Logged in', sortable: true },
        { columnid: 'permissions', title: 'User Permissions', sortable: true },
        { columnid: 'settings', title: 'User Settings', sortable: true }
      ]
    };
  }

  const makeExportReady = (userData: Array<any>) => {

    _.forEach(userData, function (user) {
      user?.permissions ? user.permissions = JSON.stringify(user.permissions) : user.permissions = '';
      user?.settings ? user.settings = JSON.stringify(user.settings) : user.settings = '';
    });
    return userData;
  }

  const exportUsersToExcel = (userData: Array<any>) => {
    const config = getColumnsConfig();
    const timestamp = dayjs().format('DD-MM-YYYY')
    const filename = ["CSTT-Users", timestamp,].join('_') + '.xls';
    return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, makeExportReady(userData)]);
  }

  return <div className="user-export-btn-container">
    {user.userDataIsLoading && <span className="spinner-container"><Spinner size='sm' /></span>}
    {!user.userDataIsLoading && <button
      type="button"
      className='btn-export sdds-btn sdds-btn-primary sdds-btn-sm'
      onClick={() => getAllData()}>
      {children && children}
    </button>
    }
  </div>;
}

export default UsersExcelExport;