import { t } from "i18next";

/* eslint-disable quote-props */
type sectionRoleMapperType = {
  [key: string]: string[]
};

type sectionItemType = {
  label: string,
  url: string,
  rolesWithAccess: Array<string>
  supportVehicle: boolean,
  isLocalRoute?: boolean,
  isNew?:boolean
};

type sectionItemMapperType = {
  [key: string]: sectionItemType[]
}

// Maps user roles to sections on homepage
const sectionRoleMapper: sectionRoleMapperType = {
  'Admin': ['Equipment', 'Customer', 'GuidedTroubleshooting', 'ConnectedServicesDeviations', 'ExternalLinks', 'Support'],
  'GTS': ['Equipment', 'Customer', 'GuidedTroubleshooting', 'ConnectedServicesDeviations', 'ExternalLinks', 'Support'],
  'LoTH': ['Equipment', 'Customer', 'GuidedTroubleshooting','ExternalLinks', 'Support'],
  'FQ': ['Equipment', 'Customer', 'GuidedTroubleshooting', 'ConnectedServicesDeviations', 'ExternalLinks', 'Support'],
  'Basic': ['Equipment', 'Customer', 'GuidedTroubleshooting', 'ExternalLinks', 'Support'],
  'BasicLimited': ['Equipment', 'GuidedTroubleshooting', 'ExternalLinks', 'Support'],
  'Assistance': ['Equipment', 'Customer', 'GuidedTroubleshooting', 'ExternalLinks', 'Support'],
};

// Maps items in each section to urls and different user roles
const sectionItemsMapper = (): sectionItemMapperType => {
  return {
    'Equipment': [
      { label: t("TS_core:Communication"), url: `https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/`, rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited','Assistance'], supportVehicle: true },
      { label: t("TS_core:ConnectedServicesDeviations"), url: `https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/connected-services-deviations`, rolesWithAccess: ['Admin', 'GTS'], supportVehicle: true },
      { label: t("TS_core:EquipmentBatchSearch"), url: `https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/equipment-batch-search`, rolesWithAccess: ['Admin', 'GTS', 'FQ'], supportVehicle: false },
      { label: t("TS_core:VehicleRtcHistory"), url: `https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/vehicle-rtc-history`, rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited','Assistance'], supportVehicle: false, isNew:true }
    ],
    'Customer': [
      { label: t("TS_core:CustomerInformation"), url: `https://customer${process.env.REACT_APP_CSTT_DOMAIN}`, rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'Assistance'], supportVehicle: false }
    ],
    'GuidedTroubleshooting': [
      { label: t("TS_core:ServiceTroubleshooting"), url: `https://servicetroubleshooting${process.env.REACT_APP_CSTT_DOMAIN}/service-troubleshooting/`, rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited', 'Assistance'], supportVehicle: true },
      { label:"Package Management", url: `https://servicetroubleshooting${process.env.REACT_APP_CSTT_DOMAIN}/package-management`, rolesWithAccess: ['Admin'], supportVehicle: false },
      { label: "Function Management", url: `https://servicetroubleshooting${process.env.REACT_APP_CSTT_DOMAIN}/function-management`, rolesWithAccess: ['Admin'], supportVehicle: false },
      { label: t("TS_temp:FaultGuideManagement"), url: `https://servicetroubleshooting${process.env.REACT_APP_CSTT_DOMAIN}/fault-guide-management`, rolesWithAccess: ['Admin', 'GTS'], supportVehicle: false }
    ],
    'ConnectedServicesDeviations': [
      { label: t("TS_core:CsdDashboard"), url: `https://csd${process.env.REACT_APP_CSTT_DOMAIN}/overview`, rolesWithAccess: ['Admin', 'GTS', 'FQ'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:CsdDefinitions"), url: `https://csd${process.env.REACT_APP_CSTT_DOMAIN}/csddefinitions`, rolesWithAccess: ['Admin'], supportVehicle: false },
      { label: t("TS_core:SystemSubscriptionManagement"), url: `https://csd${process.env.REACT_APP_CSTT_DOMAIN}/csdsystemsubscription`, rolesWithAccess: ['Admin', 'GTS', 'FQ'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:PositionDataDelay"), url: `https://csd${process.env.REACT_APP_CSTT_DOMAIN}/positiondatadelay`, rolesWithAccess: ['Admin','GTS','FQ'], supportVehicle: false, isLocalRoute: false },
    ],
    'Support': [
      { label: t("TS_core:EmailSupportTeam"), url: 'mailto:cstt.support@scania.com', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited', 'Assistance'], supportVehicle: false, isLocalRoute: true },
      // { label: t("TS_core:CSTTHelpCenter"), url: '#', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited', 'Assistance'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:MyScaniaHelpCenter"), url: 'https://fmp-help.test.cs.scania.com/#/help/get-started', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'FQ', 'Basic', 'BasicLimited', 'Assistance'], supportVehicle: false, isLocalRoute: false },
    ],
    'ExternalLinks': [
      { label: t("TS_core:DisruptionMessages"), url: `https://disturbancemessages${process.env.REACT_APP_CSTT_DOMAIN}`, rolesWithAccess: ['Admin', 'GTS'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:SPII"), url: 'https://spii.scania.com', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'Assistance', 'FQ', 'Basic', 'BasicLimited'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:WHI"), url: 'https://whi.scania.com', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'Assistance', 'FQ', 'Basic', 'BasicLimited'], supportVehicle: false, isLocalRoute: false },
      { label: t("TS_core:ExternalLinkOperationalAnalysis"), url: 'https://operationalanalysis.scania.com', rolesWithAccess: ['Admin', 'GTS', 'LoTH', 'Assistance', 'FQ', 'Basic', 'BasicLimited'], supportVehicle: false, isLocalRoute: false },
    ]
  }
};



//maps subtitles to sections on homepage
const getSubtitleForSection = (section: string): any => {
  switch (section) {
    case 'Equipment': return t("TS_core:EquipmentSectionSubTitle")
    case 'Customer': return t("TS_core:CustomerSectionSubTitle")
    case 'GuidedTroubleshooting': return t("TS_core:GuidedTroubleshootingSectionSubTitle")
    case 'ExternalLinks': return t("TS_core:ExternalLinksSubtitle")
    case 'ConnectedServicesDeviations': return t("TS_core:CSDSectionSubTitle")
    case 'Support': return t("TS_core:HelpAndFeedbackSectionSubtitle")
    default: return '';
  }
}

const getSupportedSectionsForRole = (role: string) => sectionRoleMapper[role] || [];
const getItemsForSection = (section: string, isDeveloper: boolean) => {
  let applicableItems: Array<sectionItemType> = sectionItemsMapper()[section] || [];

  if (section === 'Administration' && !isDeveloper && applicableItems.length > 0)
    applicableItems = applicableItems.filter((item) => {
      console.log(item.label);
      return item.label !== t("TS_core:CSTTUsers")
    })

  return applicableItems;
}

export default getSupportedSectionsForRole;

export {
  getSupportedSectionsForRole,
  getItemsForSection,
  getSubtitleForSection
};
