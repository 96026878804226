import React, { useEffect } from 'react';
import {  useParams } from 'react-router-dom';
type NavigatorProps = {
  to: string,
  params?: Array<string>
};

const Redirector = ({ to, params }: NavigatorProps) => {
  const { externalEquipmentReference, externalCustomerReference } = useParams();
  useEffect(() => {
    let targetUrl = to;
    if (!params || params.length === 0) {
      window.location.replace(to);
    } else {
      params.forEach(param => {
        if(externalEquipmentReference && param.toLowerCase() === 'externalequipmentreference' ){
          targetUrl = targetUrl.replace(param, externalEquipmentReference)  
        }
        if(externalCustomerReference && param.toLowerCase() === 'externalcustomerreference' ){
          targetUrl = targetUrl.replace(param, externalCustomerReference)  
        }
      });
      window.location.replace(targetUrl);
    }
  }, [])

  return <></>
};

export default Redirector;