import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import user from './user'
import { CommunicatorInfoStore, LatestInfoStore, SubscriptionInfoStore,UniversalSearchStore } from '@cd/sdds-common-components-react'
import { createSelectorHook, useDispatch } from 'react-redux'

const reducer = combineReducers({
  user,
  subscriptionInfo: SubscriptionInfoStore,
  latestInfo: LatestInfoStore,
  communicatorInfo: CommunicatorInfoStore,
  universalSearch:UniversalSearchStore
});

const store = configureStore({
  reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector = createSelectorHook<RootState>();
export default store;