import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';

const updateUserSetting = (key: string, value: string) => csttFacadeApi.put(`/csttfacade/user/v1/setting`, null, { params: { key: key, value: value } });
const changeUserRole = (role: string) => csttFacadeApi.put(`/csttfacade/user/v1/updaterole`, null, { params: { role: role } });
const getRoles = (): AxiosPromise<ProfileInformationType> => csttFacadeApi.get('/csttfacade/user/v1/roles');


const callCsttFacadeApis = (apiName: string, setApiResponse: Function, setApiLoader: Function, apiParams: Array<string>): void => {

  const getProfileInfo = (): AxiosPromise<ProfileInformationType> => csttFacadeApi.get('/csttfacade/user/v1/profile');

  switch (apiName) {
    case 'getProfileInfo':
      setApiLoader({ loading: true, error: false });
      getProfileInfo().then((response: any) => {
        setApiResponse(response.data)
        setApiLoader({ loading: false, error: false });
      })
        .catch((error: Error) => {
          console.log('getProfileInfo: ', error);
          setApiLoader({ loading: false, error: true, errorMessage: error.toString() });
        });
      break;

    case 'getRoles':
      setApiLoader({ loading: true, error: false });
      getRoles().then((response: any) => {
        setApiResponse(response.data)
        setApiLoader({ loading: false, error: false });
      })
        .catch((error: Error) => {
          setApiLoader({ loading: false, error: true, errorMessage: error.toString() });
        });
      break;

    case 'changeUserRole':
      setApiLoader({ loading: true, error: false });
      changeUserRole(apiParams[0]).then((response: any) => {
        setApiResponse(response.data)
        setApiLoader({ loading: false, error: false });
      })
        .catch((error: Error) => {
          setApiLoader({ loading: false, error: true, errorMessage: error.toString() });
        });
      break;

    case 'updateUserSetting':
      setApiLoader({ loading: true, error: false });
      updateUserSetting(apiParams[0], apiParams[1]).then((response: any) => {
        setApiResponse(response.data)
        setApiLoader({ loading: false, error: false });
      })
        .catch((error: Error) => {
          setApiLoader({ loading: false, error: true, errorMessage: error.toString() });
        });
      break;

    default: ;
  }
}

export {
  callCsttFacadeApis,
  updateUserSetting,
  changeUserRole,
  getRoles
};



