import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type HomepageSectionProps = {
  text: string,
  url: string,
  isLocalRoute?: boolean,
  isNew?: boolean
};
const HomepageSectionItem = ({ text, url, isLocalRoute, isNew }: HomepageSectionProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isLocalRoute && <div className='link-container'><Link className={'homepage-section-links sdds-body-02 ' +(url === '#' ? 'disabled':'')} to={url}>{text}</Link></div>}
      {!isLocalRoute && <div className='link-container'><a className={'homepage-section-links sdds-body-02 ' + (url === '#' ? 'disabled':'')} href={url} target="_blank" rel="noreferrer">{text}</a>
      {isNew && <div className='new-label sdds-detail-04'>{t("TS_core:NewLabel")}</div>}
      </div>}
    </>
  );
};

export default HomepageSectionItem;
