import React from "react"
import "./userStatistics.scss"
import UsersExcelExport from "../UserExcelExport";

type UserStatisticsType = {
    total: number
    roles: object
};

const UserStatistics = (props: UserStatisticsType) => {
    const { total, roles } = props
    
    return (
        <div className="main">
            <div className="title sdds-headline-05">Total CSTT Users: {total} </div>
           <UsersExcelExport><span>{'Export all user data to excel'}</span></UsersExcelExport>
            
            <div>
                <div className="rolesWrapper">
                    {roles && Object.entries(roles).map(([key, value]) => {
                        return (
                            <div key={key} className="roles">
                                <div className="role sdds-headline-06"> {key} Users </div>
                                <div className="statistic sdds-body-02"> {value} </div>
                            </div>
                        );
                    })
                    }
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default UserStatistics