import { t } from 'i18next';
import './HomepageTour.scss'

const generateTourStepsByRole = (userRole: string): any => {

    const equipementLabel: string = t("TS_core:Equipment");
    const customerLabel: string = t("TS_core:Customer");
    const troubleshootingLabel: string = t("TS_core:GuidedTroubleshooting");
    const servicesDeviationsLabel: string = t("TS_core:ConnectedServicesDeviations");
    const ExternalLinksLabel: string = t("TS_core:ExternalLinks");
    const supportLabel: string = t("TS_core:Support");
    const feedbackLabel: string =t("TS_core:SendFeedback");
    const equipmentDesciption: string = t("TS_core:W_EquipmentCard");
    const customerDesciption: string = t("TS_core:W_customerCard");
    const troubleshootingDesciption: string = t("TS_core:W_GuidedTroubleshootingCard");
    const servicesDeviationsDesciption: string = t("TS_core:W_CSDCard");
    const ExternalLinksLabelDesciption: string = t("TS_core:W_ExternalLinks");
    const supportDesciption: string = t("TS_core:W_SupportCard");
    const feedbackDescription: string = t("TS_core:W_Feedback");

    if(userRole === "Admin" || userRole === "GTS")
    {
        return([
            {
                title:<h4 className='tourTitle'>{equipementLabel}</h4>,
                target:'#cardIndex0',
                content:<p className ='pageTour'>{equipmentDesciption}</p>,
                disableBeacon: true,
            },
            {
                title:<h4 className='tourTitle'>{customerLabel}</h4>,
                target:'#cardIndex1',
                content:<p className ='pageTour'>{customerDesciption}</p>,
            },
            {
                title:<h4 className='tourTitle'>{troubleshootingLabel}</h4>,
                target:'#cardIndex2',
                content:<p className ='pageTour'>{troubleshootingDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{servicesDeviationsLabel}</h4>,
                target:'#cardIndex3',
                content:<p className ='pageTour'>{servicesDeviationsDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{ExternalLinksLabel}</h4>,
                target:'#cardIndex4',
                content:<p className ='pageTour'>{ExternalLinksLabelDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{supportLabel}</h4>,
                target:'#cardIndex5',
                content:<p className ='pageTour'>{supportDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "BasicLimited")
    {
        return([
            {
                title:<h4 className='tourTitle'>{equipementLabel}</h4>,
                target:'#cardIndex0',
                content:<p className ='pageTour'>{equipmentDesciption}</p>,
                disableBeacon: true,
            },
            {
                title:<h4 className='tourTitle'>{troubleshootingLabel}</h4>,
                target:'#cardIndex1',
                content:<p className ='pageTour'>{troubleshootingDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{ExternalLinksLabel}</h4>,
                target:'#cardIndex2',
                content:<p className ='pageTour'>{ExternalLinksLabelDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{supportLabel}</h4>,
                target:'#cardIndex3',
                content:<p className ='pageTour'>{supportDesciption}</p>
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else{
        return([// Roles[FQ, LoTH, Basic & Assistance]
        {
            title:<h4 className='tourTitle'>{equipementLabel}</h4>,
            target:'#cardIndex0',
            content:<p className ='pageTour'>{equipmentDesciption}</p>,
            disableBeacon: true
        },
        {
            title:<h4 className='tourTitle'>{customerLabel}</h4>,
            target:'#cardIndex1',
            content:<p className ='pageTour'>{customerDesciption}</p>,
        },
        {
            title:<h4 className='tourTitle'>{troubleshootingLabel}</h4>,
            target:'#cardIndex2',
            content:<p className ='pageTour'>{troubleshootingDesciption}</p>
        },
        
        {
            title:<h4 className='tourTitle'>{ExternalLinksLabel}</h4>,
            target:'#cardIndex3',
            content:<p className ='pageTour'>{ExternalLinksLabelDesciption}</p>
        },
        {
            title:<h4 className='tourTitle'>{supportLabel}</h4>,
            target:'#cardIndex4',
            content:<p className ='pageTour'>{supportDesciption}</p>
        },
        {
            title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
            target:'#surveySliderBtn',
            content:<p className ='pageTour'>{feedbackDescription}</p>
        }
    ])}
}

export default generateTourStepsByRole;

export {
    generateTourStepsByRole
};