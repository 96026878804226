import React, { useEffect, useState } from "react"
import UserStatistics from "../../components/User/UserStatistics"
import "./users.scss"

import { Spinner } from '@cd/sdds-common-components-react';


import UserSearchDetails from "../../components/User/UserSearchDetails"
import { useDispatch, useSelector } from "react-redux";
import { fetchUserStatistics, fetchUserSearch } from "../../redux/store/user";
import { debounce } from "lodash";

const Users = () => {

    const dispatch = useDispatch()
    const { user }: any = useSelector(state => state)

    const [prefix, setPrefix] = useState<string>('')
    const [currentUser, setCurrentUser] = useState<any>(null)
    
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length >= 3) {
            setCurrentUser(null)
            setIsOpen(true)
            setPrefix(e.target.value);
            dispatch(fetchUserSearch(e.target.value))
        }
    };

    const handleUserSelect = (list: any) => {
        setCurrentUser(list)
        setPrefix(`${list.firstName} ${list.lastName}`)
    };

    useEffect(() => {
        dispatch(fetchUserStatistics())
    }, []);

   

    return (
        <div className="landing-display" onClick={() => setIsOpen(false)} role="button" onKeyPress={() => setIsOpen(false)}>
            <UserStatistics total={user.userStatistics?.total} roles={user.userStatistics?.byRoles} />
            <div className="search">
                <input
                    className="searchInput"
                    placeholder="Search by scania ID, first name or last name"
                    type="text"
                    onChange={debounce(handleChange, 1000)}
                />

                {isOpen && (prefix.length > 0) &&
                    <div className="dropDown">
                        {user.isLoading && <Spinner />}
                        {Array.isArray(user.searchList) &&
                            user.searchList.length > 0 ? user.searchList.map((userInList: any) =>
                                <>
                                    <div role="button"
                                        className="suggess"
                                        onKeyPress={() => handleUserSelect(userInList)}
                                        key={userInList.scaniaId}
                                        onClick={() => handleUserSelect(userInList)}
                                    >
                                        {userInList.firstName} {userInList.lastName}
                                    </div>
                                </>
                            ) : <>{!user.isLoading && "No data found"}</>}

                    </div>
                }
            </div>
            {currentUser &&
                <UserSearchDetails userDetails={currentUser}  />
            }
        </div>
    )
}

export default Users