import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import {getSubtitleForSection} from '../../helpers/sectionRoleMapper'

type HomepageSectionProps = {
    sectionName: string,
    icon: any,
    children: any,
    idd:number
};

const HomepageSection = ({sectionName, icon, children, idd}: HomepageSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className='card' id={'cardIndex' + idd.toString()}>
      <div className='title-container'>
        <div className="icon">{icon}</div>
        <div className='card-title sdds-headline-05'> {t("TS_core:"+sectionName)} </div>
      </div>
      <div className='sub-title sdds-headline-06'>{getSubtitleForSection(sectionName)}</div>
      <div className='card-items'>
        {children}
      </div>
    </div>
  );
};

export default HomepageSection;