import React, { useState, useEffect } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter
} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import Homepage from './pages/Homepage';
import Users from './pages/Users';
import './App.css';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, Header, UserSettings, MOpinionFeedback, NavLink } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import HomepageTour from './components/HomepageTour';
import Redirector from './components/Redirector';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "role": "",
    "permissions": {
      "developer": false
    },
    "settings": {
      "csddashboard": "",
      "unit": "",
      "language": "en-GB"
    },
    "scaniaId": "",
    "impersonationAccess": false
  });

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false });

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  //For header to maintain state of which app user is currently on
  const currentApp = 'homepage';

  let identifier = window.location.href.split('?identifier=')[1];

  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (profileInfoLoader.error) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  }, [profileInfoLoader]);


  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
  }, [loggedInProfileInfo, i18n]);


  return (<>
    <div className="App">
      <sdds-theme global="true" />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
      </MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        {profileInfoLoader.loading && (<Spinner size='lg' />)}
        {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
          <RBACProvider roles={[loggedInProfileInfo.role]} >

            <HashRouter basename="/">
              <Routes>
                <Route path='csdsystemsubscription' element={<Redirector to={`${process.env.REACT_APP_CSTT_Legacy}/#/connectedservicedeviation/csdsystemsubscription`} />} />
              <Route path="equipmentsearch" element={<Redirector to={`https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication?identifier=${identifier}`} />} /> 
                <Route path="equipment/:externalEquipmentReference" element={<Redirector to={`https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/externalEquipmentReference`} params={["externalEquipmentReference"]} />} />
                <Route path="equipment/:externalEquipmentReference/communicatorsupport" element={<Redirector to={`https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/externalEquipmentReference/rtc-log`} params={["externalEquipmentReference"]} />} />
              </Routes>
            </HashRouter>
            <Router>
            <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}>
              <Header
                profileInfo={loggedInProfileInfo}
                currentApp={currentApp}
                setSearchComplete={setSearchStatus}
                setSearchStatus={setSearchResult}
                equipmentRedirectPath={'homepage'}
                tours={[<HomepageTour loggedInProfileInfo={loggedInProfileInfo} />]}
              >
                {loggedInProfileInfo?.permissions?.developer &&
                  <RBACWrapper oneOf requiredRoles={["Admin"]}>
                    <NavLink path="/users" text='Users' />
                  </RBACWrapper>}
              </Header>
              </RBACWrapper>
              <Routes>

                <Route index element={<Homepage loggedInProfileInfo={loggedInProfileInfo} />} />
                <Route path="/equipment/:idParam" element={<Homepage loggedInProfileInfo={loggedInProfileInfo} />} />
                <Route path="/customer/:idParam" element={<Homepage loggedInProfileInfo={loggedInProfileInfo} />} />
                <Route path="/users" element={<>{loggedInProfileInfo?.permissions?.developer && <RBACWrapper oneOf requiredRoles={["Admin"]}><Users /></RBACWrapper>}</>} />
                <Route path="/user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><UserSettings
                  userDetails={loggedInProfileInfo}
                  setLoggedInProfileInfo={setLoggedInProfileInfo}
                /></RBACWrapper></>} />
              </Routes>
            </Router>
          </RBACProvider>)}
        {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage}</p>)}
      </AuthenticatedTemplate>
      <MOpinionFeedback />
    </div>
  </>
  );
};

export default App;
