import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RBACWrapper } from 'react-simple-rbac';
import HomepageSection from '../../components/HomepageSection';
import HomepageSectionItem from '../../components/HomepageSection/HomepageSectionItem';
import { getItemsForSection, getSupportedSectionsForRole } from '../../helpers/sectionRoleMapper';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { UniversalSearchActions, TruckSvg, SupportSvg, WarningSvg, GuidedTroubleshootingSvg, UniversalSearchWrapper, CustomerSvg, NoRoleDisclaimer } from "@cd/sdds-common-components-react";
import './Homepage.scss';
import LinkSvg from '../../icons/LinkSvg';


type HomepageSectionItemType = {
  label: string,
  url: string,
  rolesWithAccess: Array<string>
  supportVehicle: boolean,
  isLocalRoute?: boolean,
  isNew?: boolean
};

type HomepageProps = {
  loggedInProfileInfo: ProfileInformationType,
}

const Homepage = ({ loggedInProfileInfo }: HomepageProps) => {
  const { selectedVehicle } = useAppSelector<SearchState>(state => state.universalSearch)
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [eqSearchStatus, setEqSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  const dispatch = useAppDispatch()
  const { idParam } = useParams();
  const userRole = loggedInProfileInfo.role;
  const homepageSections = getSupportedSectionsForRole(userRole);

  //maps icons to sections on homepage
  const getIconForSection = (section: string): any => {
    switch (section) {
      case 'Equipment': return <TruckSvg />
      case 'Customer': return <CustomerSvg />
      case 'GuidedTroubleshooting': return <GuidedTroubleshootingSvg />
      case 'ExternalLinks': return <LinkSvg />
      case 'ConnectedServicesDeviations': return <WarningSvg />
      case 'Support': return <SupportSvg />
      default: return <TruckSvg />;
    }
  }

  useEffect(() => {
    if (idParam) {
      dispatch(UniversalSearchActions.fetchEquipment(idParam))
      dispatch(UniversalSearchActions.fetchCustomerInfo(idParam))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParam])

  return (
  <>
   
  <div className='homepage-page'>
    <div className='title-and-search'>
      <div className='cstt-title sdds-headline-02'>
      Connected Services Troubleshooting Tool
      </div>

{(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
          <>
          <NoRoleDisclaimer />
          </>
        ) : 
       (
      <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}>
      <div className='universal-search-container'>
        <UniversalSearchWrapper setSearchResult={setSearchResult} setSearchComplete={setEqSearchStatus} equipmentRedirectPath={'homepage'} />
      </div>
      </RBACWrapper>
)
}
    </div>
    <div className='cards'>
      {homepageSections && homepageSections.map((item: string, index) => {
        return (<HomepageSection sectionName={item} icon={getIconForSection(item)} key={item + index} idd={index}>
          {getItemsForSection(item, loggedInProfileInfo?.permissions?.developer).map((item: HomepageSectionItemType, index) => {
            return <RBACWrapper oneOf requiredRoles={item.rolesWithAccess} key={index}>
              {<HomepageSectionItem
                text={item.label}
                key={`${item.label}+ index`}
                isLocalRoute={item.isLocalRoute}
                isNew={item.isNew}
                url={item.url + `${item.supportVehicle && selectedVehicle?.externalEquipmentReference ? selectedVehicle?.externalEquipmentReference : ""}`} />
              }
            </RBACWrapper>
          })}
        </HomepageSection>)
      })}
    </div>
  </div>

  </>
  )
};

export default Homepage;
