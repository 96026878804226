import { datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = () => {
    
    datadogRum.init({
        //TODO: make the applicationID and client token configurable 
        applicationId: '9c53443d-ecc2-4404-8ad3-736fb6b5f11f',
        clientToken: 'pubb35383bf916fea7e5e764c898457360f',
        site: 'datadoghq.eu',
        service:'cstt-test',
        env:'test',
        // Specify a version number to identify the deployed version of your application in Datadog 
        version: '1.0.0', 
        sessionSampleRate:100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'allow'
    });

    datadogRum.startSessionReplayRecording();
}
