import React from "react"
import "./userSearchDetails.scss"

type UserSearchDetailsTypes = {
    userDetails: any
   
};

const UserSearchDetails = (props: UserSearchDetailsTypes) => {
    const { userDetails } = props

    return (
        <div className="columns">
            <div className="column">
                <p><span className="sdds-headline-06">Scania ID :</span> {userDetails?.scaniaId}</p>
                <p><span className="sdds-headline-06">First Name :</span> {userDetails?.firstName}</p>
                <p><span className="sdds-headline-06">Last Name :</span> {userDetails?.lastName}</p>
                <p><span className="sdds-headline-06">Email :</span> {userDetails?.email}</p>
                <p><span className="sdds-headline-06">Role :</span> {userDetails?.role}</p>
                
            </div>
            <div className="column">
            <div><span className="sdds-headline-06">Settings</span>
                    <div>
                        {userDetails?.settings ? Object.entries(userDetails?.settings).map(([key, value]) => {
                            return <>
                                <div key={key}>
                                    <p><span className="sdds-u-ml2 sub-param"> {key} :</span>  {`${value}`} </p>
                                </div>
                            </>
                        }):"Null"}
                    </div>
               <span className="sdds-headline-06 sdds-u-mt3">Permissions</span>
                    <div>
                        {userDetails?.permissions ? Object.entries(userDetails?.permissions).map(([key, value]) => {
                            return <>
                                <div key={key}>
                                    <p><span className="sdds-u-ml2 sub-param"> {key} :</span>  {`${value}`} </p>
                                </div>
                            </>
                        }):"Null"}
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default UserSearchDetails
