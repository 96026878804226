import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authConfig';

import App from './App';
import './i18n';
import { initializeDataDog } from './DataDog';

ReactDOM.render(
  <Suspense fallback={<div>Loading... </div>}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
        <App />
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);

defineCustomElements();
if(process.env.REACT_APP_CSTT_DOMAIN?.includes('devtest'))
  initializeDataDog();
addTheme(scania);
